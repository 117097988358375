import dayjs from 'dayjs';

export const CLASS_UPGRADE_INITIAL_STATE = {
  is_class_upgrade: false,
  initial_mamikos_gain_percentage: 0,
  tenor: 0,
  first_repayment_date: null,
  final_repayment_date: null,
  initialBasicCommissionDisplayValue: '',
  firstRepaymentDateRealValue: null,
  firstRepaymentDateDisplayValue: '',
  finalRepaymentDateDisplayValue: '',
  marked_rooms: [],
  isAllRoomsMarked: false,
};

export const composeClassUpgradeData = ({
  classUpgrade = null,
  roomTotal = 0
} = {}) => {
  if (!classUpgrade || !classUpgrade.is_class_upgrade) {
    return CLASS_UPGRADE_INITIAL_STATE;
  }

  const {
    initial_mamikos_gain_percentage: initialCommission,
    first_repayment_date,
    final_repayment_date,
    marked_rooms
  } = classUpgrade;

  const firstRepaymentDateRealValue = dayjs(first_repayment_date).$d;

  const initialBasicCommissionDisplayValue =
    (initialCommission * 100).toString();

  const tenor = dayjs(final_repayment_date).diff(
    dayjs(first_repayment_date),
    'month'
  );

  const firstRepaymentDateDisplayValue = dayjs(
    first_repayment_date
  ).format('DD MMMM YYYY');
  const finalRepaymentDateDisplayValue = dayjs(
    final_repayment_date
  ).format('DD MMMM YYYY');

  const isAllRoomsMarked = marked_rooms.length === roomTotal;

  return {
    ...classUpgrade,
    initialBasicCommissionDisplayValue,
    tenor,
    firstRepaymentDateRealValue,
    firstRepaymentDateDisplayValue,
    finalRepaymentDateDisplayValue,
    isAllRoomsMarked,
  };
};
